// @ts-nocheck
/* eslint-disable */
/**
 * Gamelight API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SteamLastOrderData
 */
export interface SteamLastOrderData {
    /**
     * 
     * @type {string}
     * @memberof SteamLastOrderData
     */
    steamAccountId: string;
}

/**
 * Check if a given object implements the SteamLastOrderData interface.
 */
export function instanceOfSteamLastOrderData(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "steamAccountId" in value;

    return isInstance;
}

export function SteamLastOrderDataFromJSON(json: any): SteamLastOrderData {
    return SteamLastOrderDataFromJSONTyped(json, false);
}

export function SteamLastOrderDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): SteamLastOrderData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'steamAccountId': json['steam_account_id'],
    };
}

export function SteamLastOrderDataToJSON(value?: SteamLastOrderData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'steam_account_id': value.steamAccountId,
    };
}

