// @ts-nocheck
/* eslint-disable */
/**
 * Gamelight API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * * `AFN` - Afghan Afghani
 * * `ALL` - Albanian Lek
 * * `DZD` - Algerian Dinar
 * * `AOA` - Angolan Kwanza
 * * `ARS` - Argentine Peso
 * * `AMD` - Armenian Dram
 * * `AUD` - Australian Dollar
 * * `AZN` - Azerbaijani Manat
 * * `BSD` - Bahamian Dollar
 * * `BHD` - Bahraini Dinar
 * * `BDT` - Bangladeshi Taka
 * * `BBD` - Barbadian Dollar
 * * `BYN` - Belarusian Ruble
 * * `BZD` - Belize Dollar
 * * `BTN` - Bhutanese Ngultrum
 * * `BOB` - Bolivian Boliviano
 * * `BAM` - Bosnia-Herzegovina Convertible Mark
 * * `BWP` - Botswanan Pula
 * * `BRL` - Brazilian Real
 * * `GBP` - British Pound
 * * `BND` - Brunei Dollar
 * * `BGN` - Bulgarian Lev
 * * `BIF` - Burundian Franc
 * * `KHR` - Cambodian Riel
 * * `CAD` - Canadian Dollar
 * * `CVE` - Cape Verdean Escudo
 * * `XAF` - Central African CFA Franc
 * * `CLP` - Chilean Peso
 * * `CNY` - Chinese Yuan
 * * `COP` - Colombian Peso
 * * `KMF` - Comorian Franc
 * * `CDF` - Congolese Franc
 * * `CRC` - Costa Rican Colón
 * * `HRK` - Croatian Kuna
 * * `CUP` - Cuban Peso
 * * `CZK` - Czech Koruna
 * * `DKK` - Danish Krone
 * * `DJF` - Djiboutian Franc
 * * `DOP` - Dominican Peso
 * * `XCD` - East Caribbean Dollar
 * * `EGP` - Egyptian Pound
 * * `ERN` - Eritrean Nakfa
 * * `ETB` - Ethiopian Birr
 * * `EUR` - Euro
 * * `FJD` - Fijian Dollar
 * * `GMD` - Gambian Dalasi
 * * `GEL` - Georgian Lari
 * * `GHC` - Ghanaian Cedi (1979–2007)
 * * `GTQ` - Guatemalan Quetzal
 * * `GNF` - Guinean Franc
 * * `GYD` - Guyanaese Dollar
 * * `HTG` - Haitian Gourde
 * * `HNL` - Honduran Lempira
 * * `HUF` - Hungarian Forint
 * * `ISK` - Icelandic Króna
 * * `INR` - Indian Rupee
 * * `IDR` - Indonesian Rupiah
 * * `IRR` - Iranian Rial
 * * `IQD` - Iraqi Dinar
 * * `ILS` - Israeli New Shekel
 * * `JMD` - Jamaican Dollar
 * * `JPY` - Japanese Yen
 * * `JOD` - Jordanian Dinar
 * * `KZT` - Kazakhstani Tenge
 * * `KES` - Kenyan Shilling
 * * `KWD` - Kuwaiti Dinar
 * * `KGS` - Kyrgystani Som
 * * `LAK` - Laotian Kip
 * * `LBP` - Lebanese Pound
 * * `LSL` - Lesotho Loti
 * * `LRD` - Liberian Dollar
 * * `LYD` - Libyan Dinar
 * * `MKD` - Macedonian Denar
 * * `MGA` - Malagasy Ariary
 * * `MWK` - Malawian Kwacha
 * * `MYR` - Malaysian Ringgit
 * * `MVR` - Maldivian Rufiyaa
 * * `MRO` - Mauritanian Ouguiya (1973–2017)
 * * `MUR` - Mauritian Rupee
 * * `MXN` - Mexican Peso
 * * `MDL` - Moldovan Leu
 * * `MNT` - Mongolian Tugrik
 * * `MAD` - Moroccan Dirham
 * * `MZM` - Mozambican Metical (1980–2006)
 * * `MMK` - Myanmar Kyat
 * * `NAD` - Namibian Dollar
 * * `NPR` - Nepalese Rupee
 * * `NZD` - New Zealand Dollar
 * * `NIO` - Nicaraguan Córdoba
 * * `NGN` - Nigerian Naira
 * * `KPW` - North Korean Won
 * * `NOK` - Norwegian Krone
 * * `OMR` - Omani Rial
 * * `PKR` - Pakistani Rupee
 * * `PAB` - Panamanian Balboa
 * * `PGK` - Papua New Guinean Kina
 * * `PYG` - Paraguayan Guarani
 * * `PEN` - Peruvian Sol
 * * `PHP` - Philippine Peso
 * * `PLN` - Polish Zloty
 * * `QAR` - Qatari Riyal
 * * `RON` - Romanian Leu
 * * `RUB` - Russian Ruble
 * * `RWF` - Rwandan Franc
 * * `WST` - Samoan Tala
 * * `SAR` - Saudi Riyal
 * * `RSD` - Serbian Dinar
 * * `SCR` - Seychellois Rupee
 * * `SLL` - Sierra Leonean Leone (1964—2022)
 * * `SGD` - Singapore Dollar
 * * `SBD` - Solomon Islands Dollar
 * * `SOS` - Somali Shilling
 * * `ZAR` - South African Rand
 * * `KRW` - South Korean Won
 * * `SSP` - South Sudanese Pound
 * * `LKR` - Sri Lankan Rupee
 * * `SDP` - Sudanese Pound (1957–1998)
 * * `SRD` - Surinamese Dollar
 * * `SZL` - Swazi Lilangeni
 * * `SEK` - Swedish Krona
 * * `CHF` - Swiss Franc
 * * `SYP` - Syrian Pound
 * * `STD` - São Tomé & Príncipe Dobra (1977–2017)
 * * `TJS` - Tajikistani Somoni
 * * `TZS` - Tanzanian Shilling
 * * `THB` - Thai Baht
 * * `TOP` - Tongan Paʻanga
 * * `TTD` - Trinidad & Tobago Dollar
 * * `TND` - Tunisian Dinar
 * * `TRY` - Turkish Lira
 * * `TMT` - Turkmenistani Manat
 * * `USD` - US Dollar
 * * `UGX` - Ugandan Shilling
 * * `UAH` - Ukrainian Hryvnia
 * * `AED` - United Arab Emirates Dirham
 * * `UYU` - Uruguayan Peso
 * * `UZS` - Uzbekistani Som
 * * `VUV` - Vanuatu Vatu
 * * `VEB` - Venezuelan Bolívar (1871–2008)
 * * `VND` - Vietnamese Dong
 * * `XOF` - West African CFA Franc
 * * `YER` - Yemeni Rial
 * * `ZMK` - Zambian Kwacha (1968–2012)
 * @export
 */
export const CurrencyEnum = {
    Afn: 'AFN',
    All: 'ALL',
    Dzd: 'DZD',
    Aoa: 'AOA',
    Ars: 'ARS',
    Amd: 'AMD',
    Aud: 'AUD',
    Azn: 'AZN',
    Bsd: 'BSD',
    Bhd: 'BHD',
    Bdt: 'BDT',
    Bbd: 'BBD',
    Byn: 'BYN',
    Bzd: 'BZD',
    Btn: 'BTN',
    Bob: 'BOB',
    Bam: 'BAM',
    Bwp: 'BWP',
    Brl: 'BRL',
    Gbp: 'GBP',
    Bnd: 'BND',
    Bgn: 'BGN',
    Bif: 'BIF',
    Khr: 'KHR',
    Cad: 'CAD',
    Cve: 'CVE',
    Xaf: 'XAF',
    Clp: 'CLP',
    Cny: 'CNY',
    Cop: 'COP',
    Kmf: 'KMF',
    Cdf: 'CDF',
    Crc: 'CRC',
    Hrk: 'HRK',
    Cup: 'CUP',
    Czk: 'CZK',
    Dkk: 'DKK',
    Djf: 'DJF',
    Dop: 'DOP',
    Xcd: 'XCD',
    Egp: 'EGP',
    Ern: 'ERN',
    Etb: 'ETB',
    Eur: 'EUR',
    Fjd: 'FJD',
    Gmd: 'GMD',
    Gel: 'GEL',
    Ghc: 'GHC',
    Gtq: 'GTQ',
    Gnf: 'GNF',
    Gyd: 'GYD',
    Htg: 'HTG',
    Hnl: 'HNL',
    Huf: 'HUF',
    Isk: 'ISK',
    Inr: 'INR',
    Idr: 'IDR',
    Irr: 'IRR',
    Iqd: 'IQD',
    Ils: 'ILS',
    Jmd: 'JMD',
    Jpy: 'JPY',
    Jod: 'JOD',
    Kzt: 'KZT',
    Kes: 'KES',
    Kwd: 'KWD',
    Kgs: 'KGS',
    Lak: 'LAK',
    Lbp: 'LBP',
    Lsl: 'LSL',
    Lrd: 'LRD',
    Lyd: 'LYD',
    Mkd: 'MKD',
    Mga: 'MGA',
    Mwk: 'MWK',
    Myr: 'MYR',
    Mvr: 'MVR',
    Mro: 'MRO',
    Mur: 'MUR',
    Mxn: 'MXN',
    Mdl: 'MDL',
    Mnt: 'MNT',
    Mad: 'MAD',
    Mzm: 'MZM',
    Mmk: 'MMK',
    Nad: 'NAD',
    Npr: 'NPR',
    Nzd: 'NZD',
    Nio: 'NIO',
    Ngn: 'NGN',
    Kpw: 'KPW',
    Nok: 'NOK',
    Omr: 'OMR',
    Pkr: 'PKR',
    Pab: 'PAB',
    Pgk: 'PGK',
    Pyg: 'PYG',
    Pen: 'PEN',
    Php: 'PHP',
    Pln: 'PLN',
    Qar: 'QAR',
    Ron: 'RON',
    Rub: 'RUB',
    Rwf: 'RWF',
    Wst: 'WST',
    Sar: 'SAR',
    Rsd: 'RSD',
    Scr: 'SCR',
    Sll: 'SLL',
    Sgd: 'SGD',
    Sbd: 'SBD',
    Sos: 'SOS',
    Zar: 'ZAR',
    Krw: 'KRW',
    Ssp: 'SSP',
    Lkr: 'LKR',
    Sdp: 'SDP',
    Srd: 'SRD',
    Szl: 'SZL',
    Sek: 'SEK',
    Chf: 'CHF',
    Syp: 'SYP',
    Std: 'STD',
    Tjs: 'TJS',
    Tzs: 'TZS',
    Thb: 'THB',
    Top: 'TOP',
    Ttd: 'TTD',
    Tnd: 'TND',
    Try: 'TRY',
    Tmt: 'TMT',
    Usd: 'USD',
    Ugx: 'UGX',
    Uah: 'UAH',
    Aed: 'AED',
    Uyu: 'UYU',
    Uzs: 'UZS',
    Vuv: 'VUV',
    Veb: 'VEB',
    Vnd: 'VND',
    Xof: 'XOF',
    Yer: 'YER',
    Zmk: 'ZMK'
} as const;
export type CurrencyEnum = typeof CurrencyEnum[keyof typeof CurrencyEnum];


export function CurrencyEnumFromJSON(json: any): CurrencyEnum {
    return CurrencyEnumFromJSONTyped(json, false);
}

export function CurrencyEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): CurrencyEnum {
    return json as CurrencyEnum;
}

export function CurrencyEnumToJSON(value?: CurrencyEnum | null): any {
    return value as any;
}

