// @ts-nocheck
/* eslint-disable */
/**
 * Gamelight API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OrderItemRequest
 */
export interface OrderItemRequest {
    /**
     * 
     * @type {number}
     * @memberof OrderItemRequest
     */
    productId: number;
    /**
     * 
     * @type {number}
     * @memberof OrderItemRequest
     */
    quantity: number;
}

/**
 * Check if a given object implements the OrderItemRequest interface.
 */
export function instanceOfOrderItemRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "productId" in value;
    isInstance = isInstance && "quantity" in value;

    return isInstance;
}

export function OrderItemRequestFromJSON(json: any): OrderItemRequest {
    return OrderItemRequestFromJSONTyped(json, false);
}

export function OrderItemRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderItemRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'productId': json['product_id'],
        'quantity': json['quantity'],
    };
}

export function OrderItemRequestToJSON(value?: OrderItemRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'product_id': value.productId,
        'quantity': value.quantity,
    };
}

