/* tslint:disable */
/* eslint-disable */
/**
 * Gamelight API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Country,
  CountryDetail,
  GetPayNotify,
  GetPayNotifyRequest,
  MakePaymentRequest,
  OrderStatusRequest,
  Scenario,
  SelectActiveCountryRequest,
} from '../models/index';
import {
    CountryFromJSON,
    CountryToJSON,
    CountryDetailFromJSON,
    CountryDetailToJSON,
    GetPayNotifyFromJSON,
    GetPayNotifyToJSON,
    GetPayNotifyRequestFromJSON,
    GetPayNotifyRequestToJSON,
    MakePaymentRequestFromJSON,
    MakePaymentRequestToJSON,
    OrderStatusRequestFromJSON,
    OrderStatusRequestToJSON,
    ScenarioFromJSON,
    ScenarioToJSON,
    SelectActiveCountryRequestFromJSON,
    SelectActiveCountryRequestToJSON,
} from '../models/index';

export interface PaymentsApiCountriesActiveSelectRequest {
    selectActiveCountryRequest: SelectActiveCountryRequest;
}

export interface PaymentsApiGetpayNotifyCreateRequest {
    getPayNotifyRequest: GetPayNotifyRequest;
}

export interface PaymentsApiPaymentsRequest {
    name: string;
    makePaymentRequest: MakePaymentRequest;
}

export interface PaymentsApiPayselectionStatusRequest {
    orderStatusRequest: OrderStatusRequest;
}

export interface PaymentsApiPayselectionTermCreateRequest {
    orderId: string;
}

/**
 * 
 */
export class PaymentsApi extends runtime.BaseAPI {

    /**
     */
    async countriesActiveRetrieveRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CountryDetail>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/payments/countries/active/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CountryDetailFromJSON(jsonValue));
    }

    /**
     */
    async countriesActiveRetrieve(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CountryDetail> {
        const response = await this.countriesActiveRetrieveRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async countriesActiveSelectRaw(requestParameters: PaymentsApiCountriesActiveSelectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CountryDetail>> {
        if (requestParameters.selectActiveCountryRequest === null || requestParameters.selectActiveCountryRequest === undefined) {
            throw new runtime.RequiredError('selectActiveCountryRequest','Required parameter requestParameters.selectActiveCountryRequest was null or undefined when calling countriesActiveSelect.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/payments/countries/active/select/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SelectActiveCountryRequestToJSON(requestParameters.selectActiveCountryRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CountryDetailFromJSON(jsonValue));
    }

    /**
     */
    async countriesActiveSelect(requestParameters: PaymentsApiCountriesActiveSelectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CountryDetail> {
        const response = await this.countriesActiveSelectRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async countriesListRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Country>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/payments/countries/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CountryFromJSON));
    }

    /**
     */
    async countriesList(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Country>> {
        const response = await this.countriesListRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getpayNotifyCreateRaw(requestParameters: PaymentsApiGetpayNotifyCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetPayNotify>> {
        if (requestParameters.getPayNotifyRequest === null || requestParameters.getPayNotifyRequest === undefined) {
            throw new runtime.RequiredError('getPayNotifyRequest','Required parameter requestParameters.getPayNotifyRequest was null or undefined when calling getpayNotifyCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/payments/getpay/notify/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GetPayNotifyRequestToJSON(requestParameters.getPayNotifyRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetPayNotifyFromJSON(jsonValue));
    }

    /**
     */
    async getpayNotifyCreate(requestParameters: PaymentsApiGetpayNotifyCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetPayNotify> {
        const response = await this.getpayNotifyCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async paymentsRaw(requestParameters: PaymentsApiPaymentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Scenario>> {
        if (requestParameters.name === null || requestParameters.name === undefined) {
            throw new runtime.RequiredError('name','Required parameter requestParameters.name was null or undefined when calling payments.');
        }

        if (requestParameters.makePaymentRequest === null || requestParameters.makePaymentRequest === undefined) {
            throw new runtime.RequiredError('makePaymentRequest','Required parameter requestParameters.makePaymentRequest was null or undefined when calling payments.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/payments/{name}/`.replace(`{${"name"}}`, encodeURIComponent(String(requestParameters.name))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MakePaymentRequestToJSON(requestParameters.makePaymentRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ScenarioFromJSON(jsonValue));
    }

    /**
     */
    async payments(requestParameters: PaymentsApiPaymentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Scenario> {
        const response = await this.paymentsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async payselectionStatusRaw(requestParameters: PaymentsApiPayselectionStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Scenario>> {
        if (requestParameters.orderStatusRequest === null || requestParameters.orderStatusRequest === undefined) {
            throw new runtime.RequiredError('orderStatusRequest','Required parameter requestParameters.orderStatusRequest was null or undefined when calling payselectionStatus.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/payments/payselection/status/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: OrderStatusRequestToJSON(requestParameters.orderStatusRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ScenarioFromJSON(jsonValue));
    }

    /**
     */
    async payselectionStatus(requestParameters: PaymentsApiPayselectionStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Scenario> {
        const response = await this.payselectionStatusRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async payselectionTermCreateRaw(requestParameters: PaymentsApiPayselectionTermCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.orderId === null || requestParameters.orderId === undefined) {
            throw new runtime.RequiredError('orderId','Required parameter requestParameters.orderId was null or undefined when calling payselectionTermCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/payments/payselection/term/{order_id}/`.replace(`{${"order_id"}}`, encodeURIComponent(String(requestParameters.orderId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async payselectionTermCreate(requestParameters: PaymentsApiPayselectionTermCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.payselectionTermCreateRaw(requestParameters, initOverrides);
    }

}
