// @ts-nocheck
/* eslint-disable */
/**
 * Gamelight API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ExternalScenarioTyped
 */
export interface ExternalScenarioTyped {
    /**
     * 
     * @type {string}
     * @memberof ExternalScenarioTyped
     */
    type: string;
    /**
     * 
     * @type {string}
     * @memberof ExternalScenarioTyped
     */
    helpText: string;
}

/**
 * Check if a given object implements the ExternalScenarioTyped interface.
 */
export function instanceOfExternalScenarioTyped(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "helpText" in value;

    return isInstance;
}

export function ExternalScenarioTypedFromJSON(json: any): ExternalScenarioTyped {
    return ExternalScenarioTypedFromJSONTyped(json, false);
}

export function ExternalScenarioTypedFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExternalScenarioTyped {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': json['type'],
        'helpText': json['help_text'],
    };
}

export function ExternalScenarioTypedToJSON(value?: ExternalScenarioTyped | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': value.type,
        'help_text': value.helpText,
    };
}

