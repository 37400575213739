/* tslint:disable */
/* eslint-disable */
/**
 * Gamelight API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  GameDetail,
  PaginatedGameList,
} from '../models/index';
import {
    GameDetailFromJSON,
    GameDetailToJSON,
    PaginatedGameListFromJSON,
    PaginatedGameListToJSON,
} from '../models/index';

export interface GamesApiListRequest {
    limit?: number;
    offset?: number;
    search?: string;
    titleIcontains?: string;
}

export interface GamesApiRetrieveRequest {
    id: number;
}

/**
 * 
 */
export class GamesApi extends runtime.BaseAPI {

    /**
     * Список всех игр с товарами
     */
    async listRaw(requestParameters: GamesApiListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedGameList>> {
        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.titleIcontains !== undefined) {
            queryParameters['title__icontains'] = requestParameters.titleIcontains;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/games/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedGameListFromJSON(jsonValue));
    }

    /**
     * Список всех игр с товарами
     */
    async list(requestParameters: GamesApiListRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedGameList> {
        const response = await this.listRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Выбранная игра с товарами
     */
    async retrieveRaw(requestParameters: GamesApiRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GameDetail>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling retrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/games/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GameDetailFromJSON(jsonValue));
    }

    /**
     * Выбранная игра с товарами
     */
    async retrieve(requestParameters: GamesApiRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GameDetail> {
        const response = await this.retrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
