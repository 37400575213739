/* tslint:disable */
/* eslint-disable */
/**
 * Gamelight API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Article,
} from '../models/index';
import {
    ArticleFromJSON,
    ArticleToJSON,
} from '../models/index';

export interface ArticlesApiRetrieveRequest {
    slug: string;
}

/**
 * 
 */
export class ArticlesApi extends runtime.BaseAPI {

    /**
     */
    async retrieveRaw(requestParameters: ArticlesApiRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Article>> {
        if (requestParameters.slug === null || requestParameters.slug === undefined) {
            throw new runtime.RequiredError('slug','Required parameter requestParameters.slug was null or undefined when calling retrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/articles/{slug}/`.replace(`{${"slug"}}`, encodeURIComponent(String(requestParameters.slug))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ArticleFromJSON(jsonValue));
    }

    /**
     */
    async retrieve(requestParameters: ArticlesApiRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Article> {
        const response = await this.retrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
