// @ts-nocheck
/* eslint-disable */
/**
 * Gamelight API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface P2PScenarioTyped
 */
export interface P2PScenarioTyped {
    /**
     * 
     * @type {string}
     * @memberof P2PScenarioTyped
     */
    type: string;
    /**
     * 
     * @type {string}
     * @memberof P2PScenarioTyped
     */
    cardNumber: string;
    /**
     * 
     * @type {string}
     * @memberof P2PScenarioTyped
     */
    cardName: string;
}

/**
 * Check if a given object implements the P2PScenarioTyped interface.
 */
export function instanceOfP2PScenarioTyped(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "cardNumber" in value;
    isInstance = isInstance && "cardName" in value;

    return isInstance;
}

export function P2PScenarioTypedFromJSON(json: any): P2PScenarioTyped {
    return P2PScenarioTypedFromJSONTyped(json, false);
}

export function P2PScenarioTypedFromJSONTyped(json: any, ignoreDiscriminator: boolean): P2PScenarioTyped {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': json['type'],
        'cardNumber': json['card_number'],
        'cardName': json['card_name'],
    };
}

export function P2PScenarioTypedToJSON(value?: P2PScenarioTyped | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': value.type,
        'card_number': value.cardNumber,
        'card_name': value.cardName,
    };
}

