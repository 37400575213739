import type { ServersList } from "~/types/servers";
import type { ServerItem } from "~/types/servers";

import type {
  GameDetail,
  OrderHistory,
  OrderItemRequest,
  OrderResponse,
  Product,
} from "~/api_gen";

export type ModifiedProduct = { amount: number; data: Product };
export type GameData = Omit<GameDetail, "products"> & {
  products: ModifiedProduct[];
};

export const useProductsStore = defineStore("productsStore", () => {
  /**
   * Данные игры отображаемой на странице.
   */
  const gameData = ref<GameData | null>(null);
  const { t } = useI18n();
  const noProductsError = t("topup.no_products");
  const api = useApi();
  // order form errors
  const isUserMailError = ref(false);
  const isUserIdError = ref(false);
  const isUserAvatarError = ref(false);
  const isError = ref(false);
  const errorText = ref("");

  // order form values
  const inputValueMail = ref("");
  const bulldropInputValueId = ref("");
  const moogoldInputValueId = ref("");
  const standoffInputValueId = ref("");
  const steamAccountId = ref("");
  const steamAmount = ref(0);
  const steamTopupComission = ref(0);
  const inputAvatar = ref<File | null>(null);
  const gameServerId = ref();
  const surplus = ref(0);
  const totalCostSteam = ref(0);

  const defaultSteamAvatar = ref<null | string>(null);
  const repeatedOrderId = ref<number>();

  const orderId = ref();
  /**
   * Общее кол-во заказанных товаров.
   */
  const totalAmount = computed(() => {
    if (totalCostSteam.value) {
      return 1;
    } else {
      return gameData.value?.products.reduce((a, p) => a + p.amount, 0) || 0;
    }
  });
  /**
   * Общая стоимость заказанных товаров с учетом баланса
   * в валюте текущей выбранной страны.
   * Используется для заказов через карточки товара (PUBG, ZZZ)
   */
  const totalCost = computed(() => {
    const userStore = useUserStore();
    const balance = Number(userStore.userData?.balance) || 0;
    const cost =
      gameData.value?.products.reduce(
        (a, p: ModifiedProduct) =>
          a + p.amount * parseFloat(useProductPrice(p.data)),
        0,
      ) || 0;
    if (balance > cost) {
      return 0;
    } else {
      return cost - balance;
    }
  });
  /**
   * Индикатор необходимости отобразить форму подтверждения заказа.
   */
  const showOrderConfirmForm = ref(false);
  /**
   * Индикатор необходимости отобразить форму ввода данных заказчика.
   */
  const showUserDataForm = ref(false);

  const userStore = useUserStore();
  const lastOrders = ref<OrderHistory[]>();
  const moogoldGameServers = ref();
  const orderIsBlocked = ref(false);

  /**
   * Получает данные игры отображаемой на странице.
   *
   * @param slug - идентификатор игры.
   * @param isShowError
   */
  async function init(slug: string, isShowError = true) {
    try {
      const data = await api.games.retrieve({ id: Number(slug) });
      if (data) {
        const products = data.products.map((data: Product) => ({
          amount: 0,
          data,
        })) as ModifiedProduct[];
        gameData.value = Object.assign({}, data, { products });
      }
    } catch (error: any) {
      if (!isShowError) {
        gameData.value = null;
      } else {
        showError({ statusCode: error.response });
      }
    }
  }

  function restoreOrderIfExists() {
    const gamesOrderValue = localStorage.getItem("games");
    if (!gamesOrderValue || !gameData.value) {
      return;
    }

    const gamesOrder = JSON.parse(gamesOrderValue);

    if (
      gamesOrder &&
      typeof gamesOrder === "object" &&
      gameData.value?.id in gamesOrder
    ) {
      const gameOrder = gamesOrder[gameData.value.id];
      if (gameOrder.products && Array.isArray(gameOrder.products)) {
        const updatedGameOrder = { ...gameOrder, products: [] };
        gameOrder.products.forEach((p: { amount: number; data: Product }) => {
          const productToUpdate = gameData.value?.products.find(
            (prod: ModifiedProduct) => prod.data.id === p.data.id,
          );
          if (productToUpdate) {
            updatedGameOrder.products.push({
              ...productToUpdate,
              amount: p.amount,
            });
          }
        });

        gameData.value = updatedGameOrder;

        saveOrderToStorage();
      }
    }
  }

  function saveOrderToStorage() {
    if (gameData.value?.id) {
      const gamesOrderValue = localStorage.getItem("games");
      let gamesOrder = {};
      if (!gamesOrderValue) {
        localStorage.setItem(`games`, JSON.stringify({}));
      } else {
        gamesOrder = JSON.parse(gamesOrderValue);
      }

      localStorage.setItem(
        `games`,
        JSON.stringify({ ...gamesOrder, [gameData.value.id]: gameData.value }),
      );
    }
  }

  function deleteOrderFromStorage() {
    try {
      localStorage.removeItem("games");
    } catch (error: any) {
      /* empty */
    }
  }

  function deleteOrderAmounts() {
    gameData.value?.products.forEach(
      (product: ModifiedProduct) => (product.amount = 0),
    );
  }

  async function getLastOrders() {
    try {
      const data = await api.orders.list({ pageSize: 4, status: "done" });
      lastOrders.value = data.results;
    } catch (error: any) {
      /* empty */
    }
  }
  /**
   * Получает данные игры отображаемой на странице.
   *
   * @param slug - идентификатор игры.
   */
  async function setRepeatedOrderData(topup: OrderHistory) {
    await init(topup.game.id.toString());
    if (!gameData.value) return;
    gameData.value.products = gameData.value.products.map((product) => {
      const res = topup.orderProducts.find(
        (orderProduct) => orderProduct.id === product.data.id,
      );
      if (res)
        return { data: product.data, amount: res.quantity } as ModifiedProduct;
      return product;
    });
  }

  /**
   * Получает список доступных серверов для игры с использование Moogold.
   *
   * @param slug - идентификатор игры.
   */
  async function getMoogoldServersList(slug: string) {
    try {
      const data = await api.orders.supplyChannelServersList({
        gameId: Number(slug),
      });

      if (data.results) {
        moogoldGameServers.value = setServersObjectForSelect(data);
        if (!gameServerId.value) {
          gameServerId.value = moogoldGameServers.value[0].value;
        }
      }
    } catch (error: any) {
      /* empty */
    }
  }

  /**
   * Чтобы переиспользовать компонент Select нужно привести данные к одному типу.
   * Ключи приходящие с бэка не позволяют этого, поэтому формируем объект вручную.
   */
  function setServersObjectForSelect(data: ServersList) {
    const updatedData = data.results.map((server: ServerItem) => {
      const { id, ...rest } = server;
      return { value: id, ...rest };
    });
    return updatedData;
  }

  /**
   * Обновляет количество заказываемого товара используя его id.
   *
   * @param productId - id товара.
   * @param newAmount - новое количество.
   */
  function updateAmountUsingId(payload: {
    productId: number;
    newAmount: number;
  }) {
    const product = gameData.value?.products.find(
      (product) => product.data.id === payload.productId,
    );
    if (product) {
      product.amount = payload.newAmount;
      saveOrderToStorage();
    }
  }

  function productsToOrderItems() {
    const moogold = gameData.value?.products.map((product) => ({
      productId: product.data.id,
      quantity: product.amount,
    })) as OrderItemRequest[];
    return moogold?.filter((product) => product.quantity !== 0);
  }

  /**
   * Формирует корзину заказов к нужному для бэка виду и передаёт на сервер.
   */
  async function orderMoogold() {
    if (orderIsBlocked.value) return;

    isUserMailError.value = !inputValueMail.value;
    isUserIdError.value = !moogoldInputValueId.value;

    if (isUserMailError.value) {
      notify({
        text: t("top_up_balance.errors.mail_is_missing"),
        type: "error",
      });
      return;
    }

    if (isUserIdError.value) {
      notify({
        text: t("top_up_balance.errors.user_id_is_missing"),
        type: "error",
      });
      return;
    }

    orderIsBlocked.value = true;

    try {
      const data = await api.orders.supplyChannel({
        mooGoldOrderRequest: {
          products: productsToOrderItems(),
          supplyChannelServer: gameServerId.value,
          supplyChannelUserId: moogoldInputValueId.value,
          email: inputValueMail.value,
        },
      });
      handlePaymentSuccess(data);
    } catch (error: any) {
      if (!error.response) {
        isError.value = true;
        return;
      }
      const data = await error.response.json();
      if (!data) {
        isError.value = true;
        return;
      }
      if (data.email) {
        errorText.value = data.email[0];
      } else if (data._data.supply_channel_user_id) {
        errorText.value = data.supply_channel_user_id[0];
      }
    } finally {
      orderIsBlocked.value = false;
    }
  }

  /**
   * Формирует корзину заказов к нужному для бэка виду и передаёт на сервер.
   */
  async function orderPubg() {
    if (orderIsBlocked.value) return;

    isUserMailError.value = !inputValueMail.value;

    if (isUserMailError.value) {
      notify({
        text: t("top_up_balance.errors.mail_is_missing"),
        type: "error",
      });
      return;
    }

    orderIsBlocked.value = true;
    try {
      const data = await api.orders.pubg({
        pUBGOrderRequest: {
          products: productsToOrderItems(),
          email: inputValueMail.value,
        },
      });
      handlePaymentSuccess(data);
    } catch (error: any) {
      if (!error.response) {
        isError.value = true;
        return;
      }
      const data = await error.response.json();
      if (!data) {
        isError.value = true;
        return;
      }
      if (data.email) {
        errorText.value = data.email[0];
      } else if (error.data.pubg_uid) {
        errorText.value = data.pubg_uid[0];
      }
    } finally {
      orderIsBlocked.value = false;
    }
  }

  /**
   * Формирует корзину заказов к нужному для бэка виду и передаёт на сервер.
   */
  async function orderBulldrop() {
    if (orderIsBlocked.value) return;

    isUserMailError.value = !inputValueMail.value;
    isUserIdError.value = !bulldropInputValueId.value;

    if (isUserMailError.value) {
      notify({
        text: t("top_up_balance.errors.mail_is_missing"),
        type: "error",
      });
      return;
    }

    if (isUserIdError.value) {
      notify({
        text: t("top_up_balance.errors.user_id_is_missing"),
        type: "error",
      });
      return;
    }

    orderIsBlocked.value = true;

    try {
      const data = await api.orders.bulldrop({
        bulldropOrderRequest: {
          products: productsToOrderItems(),
          email: inputValueMail.value,
          bulldropUserId: Number(bulldropInputValueId.value),
        },
      });
      handlePaymentSuccess(data);
    } catch (error: any) {
      if (!error.response) {
        isError.value = true;
        return;
      }
      const data = await error.response.json();
      if (!data) {
        isError.value = true;
        return;
      }
      if (data.email) {
        errorText.value = data.email[0];
      } else if (data.bulldrop_user_id) {
        errorText.value = data.bulldrop_user_id[0];
      }
    } finally {
      orderIsBlocked.value = false;
    }
  }

  /**
   * Формирует корзину заказов к нужному для бэка виду и передаёт на сервер.
   */
  async function orderStandoffAvatar({
    orderId,
    gameAvatar,
  }: {
    gameAvatar: File;
    orderId: number;
    email: string;
  }) {
    const data = await api.orders.standoffAvatar({
      id: orderId,
      gameAvatar: gameAvatar,
    });
    return data;
  }

  /**
   * Формирует корзину заказов к нужному для бэка виду и передаёт на сервер.
   */
  async function orderStandoff() {
    if (orderIsBlocked.value) return;

    isUserMailError.value = !inputValueMail.value;
    isUserIdError.value = !standoffInputValueId.value;
    isUserAvatarError.value = !inputAvatar.value;

    if (isUserMailError.value) {
      notify({
        text: t("top_up_balance.errors.mail_is_missing"),
        type: "error",
      });
      return;
    }

    if (isUserIdError.value) {
      notify({
        text: t("top_up_balance.errors.user_id_is_missing"),
        type: "error",
      });
      return;
    }

    if (!inputAvatar.value) {
      notify({
        text: t("top_up_balance.errors.avatar_is_missing"),
        type: "error",
      });
      return;
    }

    orderIsBlocked.value = true;

    try {
      const data = await api.orders.standoff({
        standoffOrderRequest: {
          products: productsToOrderItems(),
          gameAccountId: standoffInputValueId.value,
          surplus: surplus.value.toString(),
          email: inputValueMail.value,
        },
      });
      if (!data?.id) return;
      const avatarData = await orderStandoffAvatar({
        orderId: data?.id,
        gameAvatar: inputAvatar.value,
        email: inputValueMail.value,
      });
      handlePaymentSuccess(avatarData);
    } catch (error: any) {
      if (!error.response) {
        isError.value = true;
        return;
      }
      const data = await error.response.json();
      if (!data) {
        isError.value = true;
        return;
      }
      if (data.email) {
        errorText.value = data.email[0];
      } else if (data.game_account_id) {
        errorText.value = data.game_account_id[0];
      } else if (data.surplus) {
        errorText.value = data.surplus[0];
      } else if (data.game_avatar) {
        errorText.value = data.game_avatar[0];
      }
    } finally {
      orderIsBlocked.value = false;
    }
  }

  /**
   * Формирует корзину заказов к нужному для бэка виду и передаёт на сервер.
   */
  async function orderBulldropVoucher() {
    if (orderIsBlocked.value) return;

    isUserMailError.value = !inputValueMail.value;

    if (isUserMailError.value) {
      notify({
        text: t("top_up_balance.errors.mail_is_missing"),
        type: "error",
      });
      return;
    }

    orderIsBlocked.value = true;

    try {
      const data = await api.orders.voucher({
        voucherOrderRequest: {
          products: productsToOrderItems(),
          email: inputValueMail.value,
        },
      });
      handlePaymentSuccess(data);
    } catch (error: any) {
      if (error && error.data) {
        if (error.data.email) {
          errorText.value = error.data.email[0];
        }
      } else {
        isError.value = true;
      }
    } finally {
      orderIsBlocked.value = false;
    }
  }

  const orderStandoffSettings = () => {
    return api.orders.standoffSettingsRetrieve();
  };

  function initOrderFormValues() {
    if (!inputValueMail.value)
      inputValueMail.value = userStore.userData?.email || "";
    isUserMailError.value = false;
    isUserIdError.value = false;
    isUserAvatarError.value = false;
    isError.value = false;
    errorText.value = "";
    surplus.value = Math.floor(Math.random() * 24) / 100;
  }

  const notify = useNotify();

  async function handlePaymentSuccess(arg: OrderResponse) {
    try {
      showOrderConfirmForm.value = false;
      showUserDataForm.value = false;
      orderId.value = arg.orderId;
      navigateToPayments(totalCost.value, orderId.value);
      checkIsOrdersHistoryModal();
    } catch (e: any) {
      /* empty */
    }
  }

  function navigateToPayments(
    finalCost: number,
    orderId: number,
    replace: boolean = false,
    init: number = 1,
  ) {
    document.documentElement.style.overflow = "";
    const url = `/payments?finalCost=${finalCost}&orderId=${orderId}&init=${init}`;
    navigateTo({ path: url, replace, query: { finalCost, orderId, init } });
  }

  async function getSteamComission() {
    try {
      const data = await useApi().orders.steamSettingsRetrieve();
      steamTopupComission.value = data.commission;
    } catch (e: any) {
      console.log(e);
    }
  }

  async function steamOrder() {
    const data = await useApi().orders.steam({
      steamOrderRequest: {
        amount: steamAmount.value,
        steamAccountId: steamAccountId.value,
        email: inputValueMail.value,
      },
    });
    orderId.value = data.orderId;
    totalCostSteam.value = data.total;
    navigateToPayments(data.total, data.orderId);
  }

  const continueOrder = async (id: number) => {
    const data = await api.orders.paymentDataRetrieve({ id });
    if ("url" in data) {
      window.open(data.url);
    }
  };

  /**
   * Проверяем открыто ли окно истории заказов и если да,
   * то закрываем его.
   * Эта ф-ия нужна, чтобы когда мы повторяем заказ из истории заказов, то после
   * перехода на страницу платежей не висела модалка открытой
   */
  function checkIsOrdersHistoryModal() {
    if (userStore.showLastOrderPopup) {
      userStore.showLastOrderPopup = false;
    }
  }
  return {
    gameData,
    totalCost,
    totalAmount,
    showOrderConfirmForm,
    moogoldGameServers,
    showUserDataForm,
    lastOrders,
    noProductsError,
    orderIsBlocked,
    isUserMailError,
    isUserIdError,
    isUserAvatarError,
    isError,
    errorText,
    inputValueMail,
    bulldropInputValueId,
    moogoldInputValueId,
    standoffInputValueId,
    inputAvatar,
    gameServerId,
    surplus,
    orderId,
    totalCostSteam,
    steamTopupComission,
    steamAmount,
    steamAccountId,
    repeatedOrderId,
    defaultSteamAvatar,
    init,
    initOrderFormValues,
    updateAmountUsingId,
    getMoogoldServersList,
    orderMoogold,
    getLastOrders,
    setRepeatedOrderData,
    restoreOrderIfExists,
    saveOrderToStorage,
    deleteOrderFromStorage,
    deleteOrderAmounts,
    orderBulldrop,
    orderStandoff,
    orderPubg,
    orderStandoffSettings,
    orderBulldropVoucher,
    navigateToPayments,
    steamOrder,
    continueOrder,
    getSteamComission,
  };
});
