// @ts-nocheck
/* eslint-disable */
/**
 * Gamelight API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PUBGCode } from './PUBGCode';
import {
    PUBGCodeFromJSON,
    PUBGCodeFromJSONTyped,
    PUBGCodeToJSON,
} from './PUBGCode';

/**
 * 
 * @export
 * @interface PubgOrderProduct
 */
export interface PubgOrderProduct {
    /**
     * 
     * @type {number}
     * @memberof PubgOrderProduct
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof PubgOrderProduct
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof PubgOrderProduct
     */
    icon: string;
    /**
     * 
     * @type {number}
     * @memberof PubgOrderProduct
     */
    price: number;
    /**
     * 
     * @type {string}
     * @memberof PubgOrderProduct
     */
    picture: string;
    /**
     * 
     * @type {number}
     * @memberof PubgOrderProduct
     */
    quantity: number;
    /**
     * 
     * @type {Array<PUBGCode>}
     * @memberof PubgOrderProduct
     */
    readonly codes: Array<PUBGCode>;
}

/**
 * Check if a given object implements the PubgOrderProduct interface.
 */
export function instanceOfPubgOrderProduct(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "title" in value;
    isInstance = isInstance && "icon" in value;
    isInstance = isInstance && "price" in value;
    isInstance = isInstance && "picture" in value;
    isInstance = isInstance && "quantity" in value;
    isInstance = isInstance && "codes" in value;

    return isInstance;
}

export function PubgOrderProductFromJSON(json: any): PubgOrderProduct {
    return PubgOrderProductFromJSONTyped(json, false);
}

export function PubgOrderProductFromJSONTyped(json: any, ignoreDiscriminator: boolean): PubgOrderProduct {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'title': json['title'],
        'icon': json['icon'],
        'price': json['price'],
        'picture': json['picture'],
        'quantity': json['quantity'],
        'codes': ((json['codes'] as Array<any>).map(PUBGCodeFromJSON)),
    };
}

export function PubgOrderProductToJSON(value?: PubgOrderProduct | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'title': value.title,
        'icon': value.icon,
        'price': value.price,
        'picture': value.picture,
        'quantity': value.quantity,
    };
}

