// @ts-nocheck
/* eslint-disable */
/**
 * Gamelight API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    BaseOrderHistoryTyped,
    instanceOfBaseOrderHistoryTyped,
    BaseOrderHistoryTypedFromJSON,
    BaseOrderHistoryTypedFromJSONTyped,
    BaseOrderHistoryTypedToJSON,
} from './BaseOrderHistoryTyped';
import {
    MooGoldOrderHistoryTyped,
    instanceOfMooGoldOrderHistoryTyped,
    MooGoldOrderHistoryTypedFromJSON,
    MooGoldOrderHistoryTypedFromJSONTyped,
    MooGoldOrderHistoryTypedToJSON,
} from './MooGoldOrderHistoryTyped';
import {
    PUBGOrderHistoryTyped,
    instanceOfPUBGOrderHistoryTyped,
    PUBGOrderHistoryTypedFromJSON,
    PUBGOrderHistoryTypedFromJSONTyped,
    PUBGOrderHistoryTypedToJSON,
} from './PUBGOrderHistoryTyped';
import {
    StandoffOrderHistoryTyped,
    instanceOfStandoffOrderHistoryTyped,
    StandoffOrderHistoryTypedFromJSON,
    StandoffOrderHistoryTypedFromJSONTyped,
    StandoffOrderHistoryTypedToJSON,
} from './StandoffOrderHistoryTyped';
import {
    SteamOrderHistoryTyped,
    instanceOfSteamOrderHistoryTyped,
    SteamOrderHistoryTypedFromJSON,
    SteamOrderHistoryTypedFromJSONTyped,
    SteamOrderHistoryTypedToJSON,
} from './SteamOrderHistoryTyped';
import {
    VoucherOrderHistoryTyped,
    instanceOfVoucherOrderHistoryTyped,
    VoucherOrderHistoryTypedFromJSON,
    VoucherOrderHistoryTypedFromJSONTyped,
    VoucherOrderHistoryTypedToJSON,
} from './VoucherOrderHistoryTyped';

/**
 * @type OrderHistory
 * 
 * @export
 */
export type OrderHistory = { resourcetype: 'BulldropOrder' } & BaseOrderHistoryTyped | { resourcetype: 'MooGoldOrder' } & MooGoldOrderHistoryTyped | { resourcetype: 'Order' } & BaseOrderHistoryTyped | { resourcetype: 'PUBGOrder' } & PUBGOrderHistoryTyped | { resourcetype: 'StandoffOrder' } & StandoffOrderHistoryTyped | { resourcetype: 'SteamOrder' } & SteamOrderHistoryTyped | { resourcetype: 'VoucherOrder' } & VoucherOrderHistoryTyped;

export function OrderHistoryFromJSON(json: any): OrderHistory {
    return OrderHistoryFromJSONTyped(json, false);
}

export function OrderHistoryFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderHistory {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    switch (json['resourcetype']) {
        case 'BulldropOrder':
            return {...BaseOrderHistoryTypedFromJSONTyped(json, true), resourcetype: 'BulldropOrder'};
        case 'MooGoldOrder':
            return {...MooGoldOrderHistoryTypedFromJSONTyped(json, true), resourcetype: 'MooGoldOrder'};
        case 'Order':
            return {...BaseOrderHistoryTypedFromJSONTyped(json, true), resourcetype: 'Order'};
        case 'PUBGOrder':
            return {...PUBGOrderHistoryTypedFromJSONTyped(json, true), resourcetype: 'PUBGOrder'};
        case 'StandoffOrder':
            return {...StandoffOrderHistoryTypedFromJSONTyped(json, true), resourcetype: 'StandoffOrder'};
        case 'SteamOrder':
            return {...SteamOrderHistoryTypedFromJSONTyped(json, true), resourcetype: 'SteamOrder'};
        case 'VoucherOrder':
            return {...VoucherOrderHistoryTypedFromJSONTyped(json, true), resourcetype: 'VoucherOrder'};
        default:
            throw new Error(`No variant of OrderHistory exists with 'resourcetype=${json['resourcetype']}'`);
    }
}

export function OrderHistoryToJSON(value?: OrderHistory | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    switch (value['resourcetype']) {
        case 'BulldropOrder':
            return BaseOrderHistoryTypedToJSON(value);
        case 'MooGoldOrder':
            return MooGoldOrderHistoryTypedToJSON(value);
        case 'Order':
            return BaseOrderHistoryTypedToJSON(value);
        case 'PUBGOrder':
            return PUBGOrderHistoryTypedToJSON(value);
        case 'StandoffOrder':
            return StandoffOrderHistoryTypedToJSON(value);
        case 'SteamOrder':
            return SteamOrderHistoryTypedToJSON(value);
        case 'VoucherOrder':
            return VoucherOrderHistoryTypedToJSON(value);
        default:
            throw new Error(`No variant of OrderHistory exists with 'resourcetype=${value['resourcetype']}'`);
    }

}

