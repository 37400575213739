// @ts-nocheck
/* eslint-disable */
/**
 * Gamelight API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MooGoldServersList } from './MooGoldServersList';
import {
    MooGoldServersListFromJSON,
    MooGoldServersListFromJSONTyped,
    MooGoldServersListToJSON,
} from './MooGoldServersList';

/**
 * 
 * @export
 * @interface PaginatedMooGoldServersListList
 */
export interface PaginatedMooGoldServersListList {
    /**
     * 
     * @type {number}
     * @memberof PaginatedMooGoldServersListList
     */
    count: number;
    /**
     * 
     * @type {string}
     * @memberof PaginatedMooGoldServersListList
     */
    next: string | null;
    /**
     * 
     * @type {string}
     * @memberof PaginatedMooGoldServersListList
     */
    previous: string | null;
    /**
     * 
     * @type {Array<MooGoldServersList>}
     * @memberof PaginatedMooGoldServersListList
     */
    results: Array<MooGoldServersList>;
}

/**
 * Check if a given object implements the PaginatedMooGoldServersListList interface.
 */
export function instanceOfPaginatedMooGoldServersListList(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "count" in value;
    isInstance = isInstance && "next" in value;
    isInstance = isInstance && "previous" in value;
    isInstance = isInstance && "results" in value;

    return isInstance;
}

export function PaginatedMooGoldServersListListFromJSON(json: any): PaginatedMooGoldServersListList {
    return PaginatedMooGoldServersListListFromJSONTyped(json, false);
}

export function PaginatedMooGoldServersListListFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaginatedMooGoldServersListList {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'count': json['count'],
        'next': json['next'],
        'previous': json['previous'],
        'results': ((json['results'] as Array<any>).map(MooGoldServersListFromJSON)),
    };
}

export function PaginatedMooGoldServersListListToJSON(value?: PaginatedMooGoldServersListList | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'count': value.count,
        'next': value.next,
        'previous': value.previous,
        'results': ((value.results as Array<any>).map(MooGoldServersListToJSON)),
    };
}

