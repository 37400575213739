// @ts-nocheck
/* eslint-disable */
/**
 * Gamelight API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    PromoCodeTyped,
    instanceOfPromoCodeTyped,
    PromoCodeTypedFromJSON,
    PromoCodeTypedFromJSONTyped,
    PromoCodeTypedToJSON,
} from './PromoCodeTyped';
import {
    VoucherCodeTyped,
    instanceOfVoucherCodeTyped,
    VoucherCodeTypedFromJSON,
    VoucherCodeTypedFromJSONTyped,
    VoucherCodeTypedToJSON,
} from './VoucherCodeTyped';

/**
 * @type ResponseCode
 * 
 * @export
 */
export type ResponseCode = { resourcetype: 'PromoCode' } & PromoCodeTyped | { resourcetype: 'VoucherCode' } & VoucherCodeTyped;

export function ResponseCodeFromJSON(json: any): ResponseCode {
    return ResponseCodeFromJSONTyped(json, false);
}

export function ResponseCodeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResponseCode {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    switch (json['resourcetype']) {
        case 'PromoCode':
            return {...PromoCodeTypedFromJSONTyped(json, true), resourcetype: 'PromoCode'};
        case 'VoucherCode':
            return {...VoucherCodeTypedFromJSONTyped(json, true), resourcetype: 'VoucherCode'};
        default:
            throw new Error(`No variant of ResponseCode exists with 'resourcetype=${json['resourcetype']}'`);
    }
}

export function ResponseCodeToJSON(value?: ResponseCode | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    switch (value['resourcetype']) {
        case 'PromoCode':
            return PromoCodeTypedToJSON(value);
        case 'VoucherCode':
            return VoucherCodeTypedToJSON(value);
        default:
            throw new Error(`No variant of ResponseCode exists with 'resourcetype=${value['resourcetype']}'`);
    }

}

