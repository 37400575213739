// @ts-nocheck
/* eslint-disable */
/**
 * Gamelight API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OrderResponse
 */
export interface OrderResponse {
    /**
     * 
     * @type {number}
     * @memberof OrderResponse
     */
    orderId: number;
    /**
     * 
     * @type {number}
     * @memberof OrderResponse
     */
    total: number;
}

/**
 * Check if a given object implements the OrderResponse interface.
 */
export function instanceOfOrderResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "orderId" in value;
    isInstance = isInstance && "total" in value;

    return isInstance;
}

export function OrderResponseFromJSON(json: any): OrderResponse {
    return OrderResponseFromJSONTyped(json, false);
}

export function OrderResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'orderId': json['order_id'],
        'total': json['total'],
    };
}

export function OrderResponseToJSON(value?: OrderResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'order_id': value.orderId,
        'total': value.total,
    };
}

