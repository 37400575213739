// @ts-nocheck
/* eslint-disable */
/**
 * Gamelight API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BaseProduct } from './BaseProduct';
import {
    BaseProductFromJSON,
    BaseProductFromJSONTyped,
    BaseProductToJSON,
} from './BaseProduct';

/**
 * 
 * @export
 * @interface PaginatedBaseProductList
 */
export interface PaginatedBaseProductList {
    /**
     * 
     * @type {number}
     * @memberof PaginatedBaseProductList
     */
    count: number;
    /**
     * 
     * @type {string}
     * @memberof PaginatedBaseProductList
     */
    next: string | null;
    /**
     * 
     * @type {string}
     * @memberof PaginatedBaseProductList
     */
    previous: string | null;
    /**
     * 
     * @type {Array<BaseProduct>}
     * @memberof PaginatedBaseProductList
     */
    results: Array<BaseProduct>;
}

/**
 * Check if a given object implements the PaginatedBaseProductList interface.
 */
export function instanceOfPaginatedBaseProductList(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "count" in value;
    isInstance = isInstance && "next" in value;
    isInstance = isInstance && "previous" in value;
    isInstance = isInstance && "results" in value;

    return isInstance;
}

export function PaginatedBaseProductListFromJSON(json: any): PaginatedBaseProductList {
    return PaginatedBaseProductListFromJSONTyped(json, false);
}

export function PaginatedBaseProductListFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaginatedBaseProductList {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'count': json['count'],
        'next': json['next'],
        'previous': json['previous'],
        'results': ((json['results'] as Array<any>).map(BaseProductFromJSON)),
    };
}

export function PaginatedBaseProductListToJSON(value?: PaginatedBaseProductList | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'count': value.count,
        'next': value.next,
        'previous': value.previous,
        'results': ((value.results as Array<any>).map(BaseProductToJSON)),
    };
}

