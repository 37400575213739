// @ts-nocheck
/* eslint-disable */
/**
 * Gamelight API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Game } from './Game';
import {
    GameFromJSON,
    GameFromJSONTyped,
    GameToJSON,
} from './Game';

/**
 * 
 * @export
 * @interface PaginatedGameList
 */
export interface PaginatedGameList {
    /**
     * 
     * @type {number}
     * @memberof PaginatedGameList
     */
    count: number;
    /**
     * 
     * @type {string}
     * @memberof PaginatedGameList
     */
    next?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PaginatedGameList
     */
    previous?: string | null;
    /**
     * 
     * @type {Array<Game>}
     * @memberof PaginatedGameList
     */
    results: Array<Game>;
}

/**
 * Check if a given object implements the PaginatedGameList interface.
 */
export function instanceOfPaginatedGameList(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "count" in value;
    isInstance = isInstance && "results" in value;

    return isInstance;
}

export function PaginatedGameListFromJSON(json: any): PaginatedGameList {
    return PaginatedGameListFromJSONTyped(json, false);
}

export function PaginatedGameListFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaginatedGameList {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'count': json['count'],
        'next': !exists(json, 'next') ? undefined : json['next'],
        'previous': !exists(json, 'previous') ? undefined : json['previous'],
        'results': ((json['results'] as Array<any>).map(GameFromJSON)),
    };
}

export function PaginatedGameListToJSON(value?: PaginatedGameList | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'count': value.count,
        'next': value.next,
        'previous': value.previous,
        'results': ((value.results as Array<any>).map(GameToJSON)),
    };
}

