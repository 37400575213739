// @ts-nocheck
/* eslint-disable */
/**
 * Gamelight API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { IconEnum } from './IconEnum';
import {
    IconEnumFromJSON,
    IconEnumFromJSONTyped,
    IconEnumToJSON,
} from './IconEnum';

/**
 * 
 * @export
 * @interface StandoffProductTyped
 */
export interface StandoffProductTyped {
    /**
     * 
     * @type {number}
     * @memberof StandoffProductTyped
     */
    readonly id: number;
    /**
     * 
     * @type {string}
     * @memberof StandoffProductTyped
     */
    title: string;
    /**
     * 
     * @type {IconEnum}
     * @memberof StandoffProductTyped
     */
    icon: IconEnum;
    /**
     * 
     * @type {number}
     * @memberof StandoffProductTyped
     */
    readonly price: number;
    /**
     * 
     * @type {string}
     * @memberof StandoffProductTyped
     */
    readonly discountPrice: string;
    /**
     * 
     * @type {number}
     * @memberof StandoffProductTyped
     */
    readonly discountPercent: number;
    /**
     * 
     * @type {string}
     * @memberof StandoffProductTyped
     */
    picture: string | null;
    /**
     * 
     * @type {number}
     * @memberof StandoffProductTyped
     */
    minQuantity: number;
    /**
     * 
     * @type {number}
     * @memberof StandoffProductTyped
     */
    maxQuantity: number;
    /**
     * 
     * @type {string}
     * @memberof StandoffProductTyped
     */
    description: string | null;
    /**
     * 
     * @type {string}
     * @memberof StandoffProductTyped
     */
    resourcetype: string;
}

/**
 * Check if a given object implements the StandoffProductTyped interface.
 */
export function instanceOfStandoffProductTyped(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "title" in value;
    isInstance = isInstance && "icon" in value;
    isInstance = isInstance && "price" in value;
    isInstance = isInstance && "discountPrice" in value;
    isInstance = isInstance && "discountPercent" in value;
    isInstance = isInstance && "picture" in value;
    isInstance = isInstance && "minQuantity" in value;
    isInstance = isInstance && "maxQuantity" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "resourcetype" in value;

    return isInstance;
}

export function StandoffProductTypedFromJSON(json: any): StandoffProductTyped {
    return StandoffProductTypedFromJSONTyped(json, false);
}

export function StandoffProductTypedFromJSONTyped(json: any, ignoreDiscriminator: boolean): StandoffProductTyped {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'title': json['title'],
        'icon': IconEnumFromJSON(json['icon']),
        'price': json['price'],
        'discountPrice': json['discount_price'],
        'discountPercent': json['discount_percent'],
        'picture': json['picture'],
        'minQuantity': json['min_quantity'],
        'maxQuantity': json['max_quantity'],
        'description': json['description'],
        'resourcetype': json['resourcetype'],
    };
}

export function StandoffProductTypedToJSON(value?: StandoffProductTyped | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'title': value.title,
        'icon': IconEnumToJSON(value.icon),
        'picture': value.picture,
        'min_quantity': value.minQuantity,
        'max_quantity': value.maxQuantity,
        'description': value.description,
        'resourcetype': value.resourcetype,
    };
}

