// @ts-nocheck
/* eslint-disable */
/**
 * Gamelight API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { OrderItemRequest } from './OrderItemRequest';
import {
    OrderItemRequestFromJSON,
    OrderItemRequestFromJSONTyped,
    OrderItemRequestToJSON,
} from './OrderItemRequest';

/**
 * 
 * @export
 * @interface StandoffOrderRequest
 */
export interface StandoffOrderRequest {
    /**
     * 
     * @type {Array<OrderItemRequest>}
     * @memberof StandoffOrderRequest
     */
    products: Array<OrderItemRequest>;
    /**
     * 
     * @type {string}
     * @memberof StandoffOrderRequest
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof StandoffOrderRequest
     */
    gameAccountId: string;
    /**
     * 
     * @type {string}
     * @memberof StandoffOrderRequest
     */
    surplus: string;
}

/**
 * Check if a given object implements the StandoffOrderRequest interface.
 */
export function instanceOfStandoffOrderRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "products" in value;
    isInstance = isInstance && "gameAccountId" in value;
    isInstance = isInstance && "surplus" in value;

    return isInstance;
}

export function StandoffOrderRequestFromJSON(json: any): StandoffOrderRequest {
    return StandoffOrderRequestFromJSONTyped(json, false);
}

export function StandoffOrderRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): StandoffOrderRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'products': ((json['products'] as Array<any>).map(OrderItemRequestFromJSON)),
        'email': !exists(json, 'email') ? undefined : json['email'],
        'gameAccountId': json['game_account_id'],
        'surplus': json['surplus'],
    };
}

export function StandoffOrderRequestToJSON(value?: StandoffOrderRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'products': ((value.products as Array<any>).map(OrderItemRequestToJSON)),
        'email': value.email,
        'game_account_id': value.gameAccountId,
        'surplus': value.surplus,
    };
}

