// @ts-nocheck
/* eslint-disable */
/**
 * Gamelight API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Game } from './Game';
import {
    GameFromJSON,
    GameFromJSONTyped,
    GameToJSON,
} from './Game';
import type { Product } from './Product';
import {
    ProductFromJSON,
    ProductFromJSONTyped,
    ProductToJSON,
} from './Product';

/**
 * 
 * @export
 * @interface PromoCodeTyped
 */
export interface PromoCodeTyped {
    /**
     * 
     * @type {string}
     * @memberof PromoCodeTyped
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof PromoCodeTyped
     */
    percent: string;
    /**
     * 
     * @type {Array<Game>}
     * @memberof PromoCodeTyped
     */
    readonly games: Array<Game>;
    /**
     * 
     * @type {Array<Product>}
     * @memberof PromoCodeTyped
     */
    products: Array<Product>;
    /**
     * 
     * @type {string}
     * @memberof PromoCodeTyped
     */
    resourcetype: string;
}

/**
 * Check if a given object implements the PromoCodeTyped interface.
 */
export function instanceOfPromoCodeTyped(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "code" in value;
    isInstance = isInstance && "percent" in value;
    isInstance = isInstance && "games" in value;
    isInstance = isInstance && "products" in value;
    isInstance = isInstance && "resourcetype" in value;

    return isInstance;
}

export function PromoCodeTypedFromJSON(json: any): PromoCodeTyped {
    return PromoCodeTypedFromJSONTyped(json, false);
}

export function PromoCodeTypedFromJSONTyped(json: any, ignoreDiscriminator: boolean): PromoCodeTyped {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'code': json['code'],
        'percent': json['percent'],
        'games': ((json['games'] as Array<any>).map(GameFromJSON)),
        'products': ((json['products'] as Array<any>).map(ProductFromJSON)),
        'resourcetype': json['resourcetype'],
    };
}

export function PromoCodeTypedToJSON(value?: PromoCodeTyped | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'code': value.code,
        'percent': value.percent,
        'products': ((value.products as Array<any>).map(ProductToJSON)),
        'resourcetype': value.resourcetype,
    };
}

