// @ts-nocheck
/* eslint-disable */
import lodash from "lodash";
import { ArticlesApi } from './ArticlesApi'
import { AuthApi } from './AuthApi'
import { ContactsApi } from './ContactsApi'
import { DocsApi } from './DocsApi'
import { GamesApi } from './GamesApi'
import { OrdersApi } from './OrdersApi'
import { PaymentsApi } from './PaymentsApi'
import { ProductsApi } from './ProductsApi'
import { PromoCodesApi } from './PromoCodesApi'
import { Configuration } from '../runtime'

const { bindAll, isFunction } = lodash;
const DefaultConfig = new Configuration()

export class Api {
    protected configuration: Configuration;
    public articles: ArticlesApi
    public auth: AuthApi
    public contacts: ContactsApi
    public docs: DocsApi
    public games: GamesApi
    public orders: OrdersApi
    public payments: PaymentsApi
    public products: ProductsApi
    public promoCodes: PromoCodesApi

    constructor() {
        this.configuration = this.getConfig();
        this.articles = bindAllMethods(new ArticlesApi(this.configuration))
        this.auth = bindAllMethods(new AuthApi(this.configuration))
        this.contacts = bindAllMethods(new ContactsApi(this.configuration))
        this.docs = bindAllMethods(new DocsApi(this.configuration))
        this.games = bindAllMethods(new GamesApi(this.configuration))
        this.orders = bindAllMethods(new OrdersApi(this.configuration))
        this.payments = bindAllMethods(new PaymentsApi(this.configuration))
        this.products = bindAllMethods(new ProductsApi(this.configuration))
        this.promoCodes = bindAllMethods(new PromoCodesApi(this.configuration))
    }

    protected getConfig() {
      return new Configuration();
    }
}

function bindAllMethods(obj: any) {
  return bindAll(
    obj,
    Object.getOwnPropertyNames(Object.getPrototypeOf(obj)).filter(key => isFunction(obj[key])),
  )
}
