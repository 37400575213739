// @ts-nocheck
/* eslint-disable */
/**
 * Gamelight API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SocialAuthRequest
 */
export interface SocialAuthRequest {
    /**
     * 
     * @type {string}
     * @memberof SocialAuthRequest
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof SocialAuthRequest
     */
    redirectUri: string;
    /**
     * 
     * @type {string}
     * @memberof SocialAuthRequest
     */
    email?: string | null;
}

/**
 * Check if a given object implements the SocialAuthRequest interface.
 */
export function instanceOfSocialAuthRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "code" in value;
    isInstance = isInstance && "redirectUri" in value;

    return isInstance;
}

export function SocialAuthRequestFromJSON(json: any): SocialAuthRequest {
    return SocialAuthRequestFromJSONTyped(json, false);
}

export function SocialAuthRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): SocialAuthRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'code': json['code'],
        'redirectUri': json['redirect_uri'],
        'email': !exists(json, 'email') ? undefined : json['email'],
    };
}

export function SocialAuthRequestToJSON(value?: SocialAuthRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'code': value.code,
        'redirect_uri': value.redirectUri,
        'email': value.email,
    };
}

