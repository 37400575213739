// @ts-nocheck
/* eslint-disable */
/**
 * Gamelight API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StandoffLastOrderData
 */
export interface StandoffLastOrderData {
    /**
     * 
     * @type {string}
     * @memberof StandoffLastOrderData
     */
    gameAccountId: string;
    /**
     * 
     * @type {string}
     * @memberof StandoffLastOrderData
     */
    gameAvatar: string | null;
}

/**
 * Check if a given object implements the StandoffLastOrderData interface.
 */
export function instanceOfStandoffLastOrderData(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "gameAccountId" in value;
    isInstance = isInstance && "gameAvatar" in value;

    return isInstance;
}

export function StandoffLastOrderDataFromJSON(json: any): StandoffLastOrderData {
    return StandoffLastOrderDataFromJSONTyped(json, false);
}

export function StandoffLastOrderDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): StandoffLastOrderData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'gameAccountId': json['game_account_id'],
        'gameAvatar': json['game_avatar'],
    };
}

export function StandoffLastOrderDataToJSON(value?: StandoffLastOrderData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'game_account_id': value.gameAccountId,
        'game_avatar': value.gameAvatar,
    };
}

