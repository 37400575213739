// @ts-nocheck
/* eslint-disable */
/**
 * Gamelight API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    BaseGameDetailTyped,
    instanceOfBaseGameDetailTyped,
    BaseGameDetailTypedFromJSON,
    BaseGameDetailTypedFromJSONTyped,
    BaseGameDetailTypedToJSON,
} from './BaseGameDetailTyped';
import {
    MooGoldGameDetailTyped,
    instanceOfMooGoldGameDetailTyped,
    MooGoldGameDetailTypedFromJSON,
    MooGoldGameDetailTypedFromJSONTyped,
    MooGoldGameDetailTypedToJSON,
} from './MooGoldGameDetailTyped';

/**
 * @type GameDetail
 * 
 * @export
 */
export type GameDetail = { resourcetype: 'BulldropGame' } & BaseGameDetailTyped | { resourcetype: 'Game' } & BaseGameDetailTyped | { resourcetype: 'PUBGGame' } & BaseGameDetailTyped | { resourcetype: 'StandoffGame' } & BaseGameDetailTyped | { resourcetype: 'Steam' } & BaseGameDetailTyped | { resourcetype: 'SupplyGame' } & MooGoldGameDetailTyped | { resourcetype: 'Voucher' } & BaseGameDetailTyped;

export function GameDetailFromJSON(json: any): GameDetail {
    return GameDetailFromJSONTyped(json, false);
}

export function GameDetailFromJSONTyped(json: any, ignoreDiscriminator: boolean): GameDetail {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    switch (json['resourcetype']) {
        case 'BulldropGame':
            return {...BaseGameDetailTypedFromJSONTyped(json, true), resourcetype: 'BulldropGame'};
        case 'Game':
            return {...BaseGameDetailTypedFromJSONTyped(json, true), resourcetype: 'Game'};
        case 'PUBGGame':
            return {...BaseGameDetailTypedFromJSONTyped(json, true), resourcetype: 'PUBGGame'};
        case 'StandoffGame':
            return {...BaseGameDetailTypedFromJSONTyped(json, true), resourcetype: 'StandoffGame'};
        case 'Steam':
            return {...BaseGameDetailTypedFromJSONTyped(json, true), resourcetype: 'Steam'};
        case 'SupplyGame':
            return {...MooGoldGameDetailTypedFromJSONTyped(json, true), resourcetype: 'SupplyGame'};
        case 'Voucher':
            return {...BaseGameDetailTypedFromJSONTyped(json, true), resourcetype: 'Voucher'};
        default:
            throw new Error(`No variant of GameDetail exists with 'resourcetype=${json['resourcetype']}'`);
    }
}

export function GameDetailToJSON(value?: GameDetail | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    switch (value['resourcetype']) {
        case 'BulldropGame':
            return BaseGameDetailTypedToJSON(value);
        case 'Game':
            return BaseGameDetailTypedToJSON(value);
        case 'PUBGGame':
            return BaseGameDetailTypedToJSON(value);
        case 'StandoffGame':
            return BaseGameDetailTypedToJSON(value);
        case 'Steam':
            return BaseGameDetailTypedToJSON(value);
        case 'SupplyGame':
            return MooGoldGameDetailTypedToJSON(value);
        case 'Voucher':
            return BaseGameDetailTypedToJSON(value);
        default:
            throw new Error(`No variant of GameDetail exists with 'resourcetype=${value['resourcetype']}'`);
    }

}

