// @ts-nocheck
/* eslint-disable */
/**
 * Gamelight API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Contacts } from './Contacts';
import {
    ContactsFromJSON,
    ContactsFromJSONTyped,
    ContactsToJSON,
} from './Contacts';

/**
 * 
 * @export
 * @interface PaginatedContactsList
 */
export interface PaginatedContactsList {
    /**
     * 
     * @type {number}
     * @memberof PaginatedContactsList
     */
    count: number;
    /**
     * 
     * @type {string}
     * @memberof PaginatedContactsList
     */
    next: string | null;
    /**
     * 
     * @type {string}
     * @memberof PaginatedContactsList
     */
    previous: string | null;
    /**
     * 
     * @type {Array<Contacts>}
     * @memberof PaginatedContactsList
     */
    results: Array<Contacts>;
}

/**
 * Check if a given object implements the PaginatedContactsList interface.
 */
export function instanceOfPaginatedContactsList(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "count" in value;
    isInstance = isInstance && "next" in value;
    isInstance = isInstance && "previous" in value;
    isInstance = isInstance && "results" in value;

    return isInstance;
}

export function PaginatedContactsListFromJSON(json: any): PaginatedContactsList {
    return PaginatedContactsListFromJSONTyped(json, false);
}

export function PaginatedContactsListFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaginatedContactsList {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'count': json['count'],
        'next': json['next'],
        'previous': json['previous'],
        'results': ((json['results'] as Array<any>).map(ContactsFromJSON)),
    };
}

export function PaginatedContactsListToJSON(value?: PaginatedContactsList | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'count': value.count,
        'next': value.next,
        'previous': value.previous,
        'results': ((value.results as Array<any>).map(ContactsToJSON)),
    };
}

